import { postPwdLogin, postPwdPay } from "@/api";
export default {
  data() {
    return {
      barIndex: 0,
      old_pwd: "",
      pwd: "",
      pwd2: ""
    };
  },
  methods: {
    choseBar(index) {
      this.barIndex = index;
      this.old_pwd = "";
      this.pwd = "";
      this.pwd2 = "";
    },
    submit() {
      if (!this.old_pwd) {
        this.$toast("请输入原密码");
        return;
      }
      if (this.pwd.length < 6 || this.pwd2.length < 6) {
        this.$toast("密码格式错误");
        return;
      }
      if (this.pwd !== this.pwd2) {
        this.$toast("两次密码输入不一致");
        return;
      }
      let data = {
        old_pwd: this.old_pwd,
        pwd: this.pwd,
        pwd2: this.pwd2
      };
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      this.old_pwd = "";
      this.pwd = "";
      this.pwd2 = "";
      if (this.barIndex == 0) {
        postPwdLogin({
          ...data
        }).then(res => {
          this.$closeToast();
          this.$toast(res.msg);
          this.$router.go(-1);
        });
      } else {
        postPwdPay({
          ...data
        }).then(res => {
          this.$closeToast();
          this.$toast(res.msg);
          this.$router.go(-1);
        });
      }
    }
  }
};